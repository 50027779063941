import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { BillingService } from "../../pages/billing/billing.service";
import { AuthService } from "../auth.service";
import { LoginService } from "./login.service";
import * as uuid from "uuid";
import firebase from "firebase";
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient, HttpParams } from "@angular/common/http";
import { data } from "jquery";
import { MixpanelService } from "../../shared/service/mixpanel.service";
import * as mixpanel from "mixpanel-browser";
import { AgencyService } from "../../pages/agency/agency.service";
import { CommonService } from "../../pages/common.service";

declare let pendo: any;
declare let analytics: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  showSignpage: boolean = true;
  password = "password";
  googleLogintoken = "";
  // clientId = '427964124465-lee6i48l5pphhovmorqk318bbppehgmi.apps.googleusercontent.com'
  clientId =
    "427964124465-lee6i48l5pphhovmorqk318bbppehgmi.apps.googleusercontent.com";
  // clinetSec = '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';
  clinetSec = "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";
  button_spinner = false;

  constructor(
    private billing: BillingService,
    private spinner: NgxSpinnerService,
    private route: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private authService: SocialAuthService,
    private afAuth: AngularFireAuth,
    private http: HttpClient,
    private login: LoginService,
    private mixPanelservice: MixpanelService,
    private agency: AgencyService,
    private common:CommonService
  ) {}

  ngOnInit(): void {
    // localStorage.clear();
    // sessionStorage.clear();
    localStorage.removeItem("authInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("client_secret");
    localStorage.removeItem("publishable_key");
    localStorage.removeItem("sub_type");
    localStorage.removeItem("amount");
    localStorage.removeItem("plan_type");
    localStorage.removeItem("plan_interval");
    localStorage.removeItem("headerHeading");
    localStorage.removeItem("from_page");
    localStorage.removeItem("is_google_login");
    localStorage.removeItem("edit_trugger");
    localStorage.removeItem("first_step");
    localStorage.removeItem("susbcribe");
    localStorage.removeItem("isNewUser");
    localStorage.removeItem("profileUpdationFlag");
    localStorage.removeItem("trigger_Name");
    localStorage.removeItem("linkedinId");
    localStorage.removeItem("email");
    localStorage.removeItem("agency");
    let params = {};
    let regex = /([^&=]+)=([^&]*)/g,
      m;
    while ((m = regex.exec(location.href))) {
      params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    }
    if (Object.keys(params).length > 0) {
      localStorage.setItem("authInfo", JSON.stringify(params));
      // console.log(params);

      let info = JSON.parse(localStorage.getItem("authInfo"));
      this.googleLogintoken = info.access_token;
      // console.log(info);
      window.history.pushState({}, document.title, "" + "login");
      if (this.googleLogintoken.length > 0) {
        this.signInWithGoogle();
      } else {
      }
    }
  }

  showOrhidePassword() {
    if (this.password === "password") {
      this.password = "text";
    } else {
      this.password = "password";
    }
  }

  loginForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(6),
    ]),
  });

  otpForm = new FormGroup({
    otp: new FormControl("", Validators.required),
  });

  onSubmit() {
    // this.spinner.show();
    this.button_spinner = true;
    this.loginForm.value;
    this.auth.login(this.loginForm.value).subscribe((response) => {
      // console.log(response);
      // this.spinner.hide();
      this.button_spinner = false;
      if (response.status == 200) {
        localStorage.setItem("isNewUser", response.body.is_new_user);
        localStorage.setItem("setIntervaltimeOut", "true");
        localStorage.setItem("is_google_login", response.body.is_google_login);
        this.toastr.success("Login successful. Welcome to Triggify!", "", {
          closeButton: true,
          positionClass: "toast-bottom-center",
        });
        const myId = uuid.v4();
        analytics.identify(response.body.id, {
          email: response.body.email,
          // Optional
          name: response.body.name,
          // avatar: 'https://avatar.com/asd809sdhoif9as10nc29.png'
          // Add anything else about the user here
        });
        analytics.track("Login", {
          email: response.body.email,
        });
        pendo.initialize({
          visitor: {
            id: response.body.id,
            email: response.body.email,
            full_name: response.body.name,
            role: "User",
          },
          account: {
            id: myId,
          },
        });
        mixpanel.identify(response.body.id);
        // localStorage.setItem("email", this.loginForm.value.email.toLowerCase());
        localStorage.setItem("token", response.body.token);
        localStorage.setItem("referral_used", response.body.referral_used);
        localStorage.setItem("email", response.body.email);
        localStorage.setItem("id", response.body.id);
        localStorage.setItem("name", response.body.name);
        localStorage.setItem("uuid", myId);
        this.billing.currentPlan().subscribe((response) => {
          // console.log(res,"current plan");
          if (response.body.message == "No subscription found") {
            this.agency.llinkedincredList().subscribe(res=>{
              if (res.body.length > 0) {
                localStorage.setItem('linkedinId',res.body[0].id)
                // this.common.linkedinId.next(res.body[0].id)
              }
            })
            localStorage.setItem("susbcribe", "False");
            this.route.navigate(["plan"]);
          } else if(response.body.message.description == null || response.body.message.description == 'null'){
            localStorage.setItem("susbcribe", "False");
            this.route.navigate(["plan"]);
          } else {
            localStorage.setItem("sub_type", response.body.message.description);
            this.agency.llinkedincredList().subscribe((res) => {
              if (res.body.length == 0) {
                if (response.body.message.description.includes("Agency")) {
                  this.route.navigate(["agency-plan"]);
                  localStorage.setItem("agency", "True");
                } else {
                  this.route.navigate(["plan"]);
                  localStorage.setItem("susbcribe", "False");
                  localStorage.setItem("headerHeading", "free");
                  // localStorage.setItem("sub_type", "free");
                  localStorage.setItem("first_step", "true");
                  localStorage.setItem("agency", "false");
                }
              } else {
                if (response.body.message.description.includes("Agency")) {
                  localStorage.setItem("agency", "True");
                } else {
                  localStorage.setItem("agency", "false");
                }
                localStorage.setItem('linkedinId',res.body[0].id)
                // this.common.linkedinId.next(res.body[0].id)
                this.route.navigate(["dashboard/view_all"]);
                localStorage.setItem("susbcribe", "True");
              }
            });
          }
        });
      } else {
        if (
          response.error.message ==
          "You have entered invalid username or password."
        ) {
          this.toastr.error("Email ID or Password is not correct", "", {
            closeButton: true,
            positionClass: "toast-bottom-center",
          });
        }
        if (response.error.message == "user is not verified") {
          this.toastr.error(response.error.message, "", {
            closeButton: true,
            positionClass: "toast-bottom-center",
          });
          this.showSignpage = false;
          this.auth
            .getOTPforverification(this.loginForm.value.email)
            .subscribe((res) => {
              // console.log(res,"email otp send");
              this.toastr.success(res.body.message, "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
            });
        }
      }
    });
  }

  verifyUser() {
    // console.log("otp", this.otpForm.value);
    this.auth
      .verifyUserEMailid(this.loginForm.value.email, this.otpForm.value.otp)
      .subscribe((res) => {
        // console.log(res);
        this.mixPanelservice.track("Completed email verification", {});
        if (res.status == 200) {
          this.toastr.success(
            "Registration complete! Please login and continue",
            "",
            {
              closeButton: false,
              positionClass: "toast-bottom-center",
            }
          );
          this.showSignpage = true;
        } else {
          this.toastr.error(res.error.mssage, "", {
            closeButton: false,
            positionClass: "toast-bottom-center",
          });
        }
      });
  }

  hide() {
    this.showSignpage = true;
  }

  // Login With google

  signInWithGoogle(): void {
    this.auth.signInwithGoogle(this.googleLogintoken).subscribe((response) => {
      // console.log(response, "response from imran api");
      if (response.status == 200) {
        localStorage.setItem("token", response.body.token);
        localStorage.setItem("referral_used", response.body.referral_used);
        localStorage.setItem("email", response.body.email);
        localStorage.removeItem("authInfo");
        // this.auth.sendEmail(response.body.email).subscribe((res) => {
        //   // console.log(res,"email added reposnse")
        // });
        localStorage.setItem("setIntervaltimeOut", "true");
        localStorage.setItem("isNewUser", response.body.is_new_user);
        localStorage.setItem("is_google_login", response.body.is_google_login);
        this.toastr.success("Login successful. Welcome to Triggify!", "", {
          closeButton: true,
          positionClass: "toast-bottom-center",
        });
        analytics.identify(response.body.id, {
          email: response.body.email,
        });
        analytics.track("Login with Google", {
          email: response.body.email,
        });

        // localStorage.setItem("email", this.loginForm.value.email.toLowerCase());
        // localStorage.setItem("token", response.body.token);

        // localStorage.setItem("email", response.body.email);
        // localStorage.setItem("id", response.body.id);
        // localStorage.setItem("name", response.body.name);
        this.billing.currentPlan().subscribe((response) => {
          // console.log(res,"current plan");
          if (response.body.message == "No subscription found") {
            localStorage.setItem("susbcribe", "False");
            this.route.navigate(["plan"]);
          } else if(response.body.message.description == null || response.body.message.description == 'null'){
            localStorage.setItem("susbcribe", "False");
            this.route.navigate(["plan"]);
          } else {
            localStorage.setItem("sub_type", response.body.message.description);

            this.agency.llinkedincredList().subscribe((res) => {
              console.log(res, "response");
              if (res.body.length == 0) {
                if (response.body.message.description.includes("Agency")) {
                  this.route.navigate(["agency-plan"]);
                  localStorage.setItem("agency", "True");
                } else {
                  localStorage.setItem('linkedinId',res.body[0].id)
                  this.route.navigate(["plan"]);
                  localStorage.setItem("susbcribe", "False");
                  localStorage.setItem("headerHeading", "free");
                  // localStorage.setItem("sub_type", "free");
                  localStorage.setItem("first_step", "true");
                  localStorage.setItem("agency", "false");
                }
              } else {
                if (response.body.message.description.includes("Agency")) {
                  localStorage.setItem("agency", "True");
                } else {
                  localStorage.setItem("agency", "false");
                }
                localStorage.setItem('linkedinId',res.body[0].id)
                // this.common.linkedinId.next(res.body[0].id)
                this.route.navigate(["dashboard/view_all"]);
                localStorage.setItem("susbcribe", "True");
              }
            });
          }
        });
      } else {
        if (
          response.error.message ==
          "You have entered invalid username or password."
        ) {
          this.toastr.error("Email ID or Password is not correct", "", {
            closeButton: true,
            positionClass: "toast-bottom-center",
          });
        }
        if (response.error.message == "user is not verified") {
          this.toastr.error(response.error.message, "", {
            closeButton: true,
            positionClass: "toast-bottom-center",
          });
          this.showSignpage = false;
          this.auth
            .getOTPforverification(this.loginForm.value.email)
            .subscribe((res) => {
              // console.log(res,"email otp send");
              this.toastr.success(res.body.message, "", {
                closeButton: true,
                positionClass: "toast-bottom-center",
              });
            });
        }
      }
    });

    // this.signInwithGoogleAPI()
  }

  signInWithGoogle1(): void {
    this.afAuth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((userCredential) => {
        // Handle the signed-in user
        const user = userCredential.user;
        // console.log("Signed in as: " + user.displayName);

        // Get the authentication token
        user.getIdToken().then((idToken) => {
          // console.log("Authentication Token:", idToken);
          // You can send this token to your server for authentication or other purposes.
        });
      })
      .catch((error) => {
        // Handle errors
        // console.error("Google Sign-In Error:", error);
      });
  }

  signInwithGoogleAPI() {
    // return this.http.get<any>(
    //   `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&include_granted_scopes=true&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid&state=state_parameter_passthrough_value&redirect_uri=https://app.triggify.io/&client_id=427964124465-lee6i48l5pphhovmorqk318bbppehgmi.apps.googleusercontent.com`
    // );
    let url = "https://accounts.google.com/o/oauth2/v2/auth";
    let form = document.createElement("form");
    form.setAttribute("method", "GET");
    form.setAttribute("Action", url);

    let params = {
      response_type: "token",
      client_id: this.clientId,
      // redirect_uri: "https://app.triggify.io/login",
      // redirect_uri: "http://localhost:4200/login",
      redirect_uri: "https://app-dev.triggify.io/login",
      scope:
        "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid",
      state: "pass-through-value",
      include_granted_scopes: "true",
      prompt: "select_account",
    };

    for (var p in params) {
      let input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", p);
      input.setAttribute("value", params[p]);
      form.appendChild(input);
    }
    document.body.appendChild(form);
    form.submit();
  }

  // getToken() {
  //   let data = {
  //     code: this.googleLogintoken,
  //     client_id:this.clientId,
  //     client_secret: this.clinetSec,
  //     redirect_uri: "http://localhost:4200",
  //     grant_type: "authorization_code",
  //   };
  //   return this.http.post<any>(`https://oauth2.googleapis.com/token`, data);
  // }

  resendOTP() {
    this.auth
      .getOTPforverification(this.loginForm.value.email)
      .subscribe((res) => {
        // console.log(res,"email otp send");
        this.toastr.success(res.body.message, "", {
          closeButton: true,
          positionClass: "toast-bottom-center",
        });
      });
  }
}
